export const require = function (url, callback) {
  if (Array.isArray(url) && url.length > 0) url = url[0]
  const scriptTag = document.createElement('script')
  scriptTag.type = 'text/javascript'
 
  scriptTag.onreadystatechange = function () { // IE 
    if (scriptTag.readyState === 'loaded' || scriptTag.readyState === 'complete') {
      callback()
    }
  }

  scriptTag.onload = callback // all browsers

  scriptTag.src = url
  document.head.appendChild(scriptTag)
}